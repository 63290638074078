import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: (resolve) => require(["../views/index.vue"], resolve),
    redirect: "home",
    children: [
      {
        path: "/home",
        name: "home",
        component: (resolve) => require(["../views/home.vue"], resolve),
        meta: {
          title: "首页"
        }
      },
      {
        path: "/ppjs",
        name: "ppjs",
        component: (resolve) => require(["../views/ppjs.vue"], resolve),
        meta: {
          title: "品牌介绍"
        }
      },
      {
        path: "/kctx",
        name: "kctx",
        component: (resolve) => require(["../views/kctx.vue"], resolve),
        meta: {
          title: "课程体系"
        }
      },
      {
        path: "/zshz",
        name: "zshz",
        component: (resolve) => require(["../views/zshz.vue"], resolve),
        meta: {
          title: "招商合作"
        }
      },
      {
        path: "/qgxq",
        name: "qgxq",
        component: (resolve) => require(["../views/qgxq.vue"], resolve),
        meta: {
          title: "全国校区"
        }
      },
      {
        path: "/xwdt",
        name: "xwdt",
        component: (resolve) => require(["../views/xwdt.vue"], resolve),
        meta: {
          title: "新闻动态"
        }
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

export default router;
